<template>
  <div class="theoretical-value-page">
    <div class="box center search-wrapper">
      <div class="box center search">
        <el-form :model="operateForm" :inline="true" ref="operateForm" label-width="85px" class="my-form">
          <el-form-item label="监测类型" prop="type">
            <el-select v-model="operateForm.typeId" placeholder="请选择" @change="handleTypeChange">
              <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="监测指标" prop="quotaId">
            <el-select v-model="operateForm.quotaId" placeholder="请选择">
              <el-option v-for="item in targetOptions" :key="item.quotaId" :label="item.quotaName" :value="item.quotaId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="operateForm.code" placeholder="请输入测点编号"></el-input>
          </el-form-item>
          <el-button @click="handleSearch">查询</el-button>
          <el-button class="reset-btn" @click="handleReset">重置</el-button>
        </el-form>
      </div>
      <div class="btn-right-wrapper">
        <el-button @click="handleMultiple" :disabled="!selectTables.length" :class="!myOption ? 'disable_icon' : ''">批量设置</el-button>
        <div class="template-wrapper" @mouseenter="handleTemplateEnter" @mouseleave="handleTemplateLeave">
          <el-button>模板下载 <i class="el-icon-arrow-down"></i></el-button>
          <ul class="template-ul" v-show="isShowTemplateUl">
            <li @click="handleMoveDownLoad('1')">静态模板下载</li>
            <li @click="handleMoveDownLoad('2')" :class="!myDynamic ? 'disable_icon' : ''">动态模板下载</li>
          </ul>
        </div>
        <div class="import-wrapper" @mouseenter="handleImportEnter" @mouseleave="handleImportLeave">
          <el-button>导入 <i class="el-icon-arrow-down"></i></el-button>
          <ul class="import-ul" v-show="isShowImportUl">
            <li @click="handleQuietImport">静态模板导入</li>
            <li @click="handleMoveImport" :class="!myDynamic ? 'disable_icon' : ''">动态模板导入</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="table-wrapper" v-if="tableData.length">
      <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#04153F', color: '#fff' }" :data="tableData" @selection-change="handleSelectionChange" :height="tableHeight">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="code" label="测点编号"></el-table-column>
        <el-table-column prop="typeName" label="监测类型"></el-table-column>
        <el-table-column prop="quotaName" label="监测指标"></el-table-column>
        <el-table-column prop="site" label="位置描述"></el-table-column>
        <el-table-column prop="id" label="操作">
          <template slot-scope="scope">
            <span class="operate-txt" @click="handleSettings(scope.row)" :class="!myOption ? 'disable_icon' : ''">设置</span>
          </template>
        </el-table-column>
      </el-table>
      <common-pagination v-show="tableData.length > 0" class="pageMain" :pageSize="pageSize" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </div>
    <div class="noDataMain" v-if="!tableData.length">
      <img src="../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>
    <!-- 理论值设置 -->
    <el-dialog title="理论值设置" :visible.sync="valueDialogVisible" class="common-dialog little-dialog" top="20vh" :before-close="handleValueDialogClose">
      <div class="operate-wrapper">
        <div class="content-wrapper">
          <div class="point-info-wrapper">
            <div class="title">
              <el-divider direction="vertical" class="my-shu-divider"></el-divider>
              <span class="title-name">测点信息</span>
            </div>
            <table class="my-value-table" v-show="!isMultiple">
              <tr>
                <th>测点编号</th>
                <td>{{ details.code }}</td>
                <th>监测指标</th>
                <td>{{ details.quotaName }}</td>
              </tr>
              <tr>
                <th>位置描述</th>
                <td colspan="3">{{ details.site }}</td>
              </tr>
            </table>
            <table class="my-multiple-table" v-show="isMultiple">
              <tr v-for="item in selectTables" :key="item.id">
                <td>测点编号</td>
                <td>{{ item.code }}</td>
              </tr>
            </table>
          </div>
          <el-divider class="my-divider"></el-divider>
          <div class="value-wrapper">
            <div class="dynamic-value">
              <el-checkbox v-model="dynamicValue" @change="handleDynamicValueChange" :disabled="isMultiple">动态理论值</el-checkbox>
            </div>
            <div v-if="!dynamicValue" class="value-input-wrapper">
              <span class="fs12">理论值</span>
              <el-input v-model="theoreticalValue" placeholder="请输入理论值"></el-input>
              <span class="unit">{{details.unit}}</span>
            </div>
            <div v-if="dynamicValue" class="content">
              <el-table :data="dynamicTableData">
                <el-table-column prop="mileage" width="90" label="方向"></el-table-column>
                <el-table-column prop="linkName" width="80" label="施工节段"></el-table-column>
                <el-table-column prop="workingName" label="工况"></el-table-column>
                <el-table-column prop="value" label="理论值" width="140">
                  <template slot-scope="scope">
                    <el-input style="width: 100%" v-model="scope.row.value" placeholder="请输入"></el-input>
                    {{details.unit}}
                </template>
                </el-table-column>
                <el-table-column width="15"></el-table-column>
              </el-table>
            </div>
            <!-- <div class="description">{{description}}</div> -->
          </div>
        </div>
        <div class="btn-footer">
          <el-button class="save-btn" @click="handleValueSave">保存</el-button>
          <el-button class="cancel-btn" @click="handleValueDialogClose">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog :title="operateType == 'quiet' ? '静态模板导入' : '动态模板导入'" :visible.sync="uploadDialogVisible" class="common-dialog little-dialog" top="15vh" :before-close="handleUploadDialogClose">
      <div class="operate-wrapper">
        <el-form :model="uploadForm" :inline="true" :rules="uploadRules" ref="uploadForm" label-width="90px" class="my-form">
          <el-form-item label="文件" prop="file">
            <el-upload class="upload-demo" :headers="{ token }" :limit="1" ref="upload" :auto-upload="false" :file-list="uploadFileList" :on-remove="handleRemove" :on-change="handleChange" action="">
              <el-button class="upload-btn">选择</el-button>
              <span class="tips">上传文件不超过50M</span>
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="btn-footer">
          <el-button @click="handleUploadSave">保存</el-button>
          <el-button class="cancel-btn" @click="handleUploadDialogClose">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonPagination from "@/components/Pagination";
import axios from "axios";
export default {
  data() {
    return {
      loadingPage: null,
      projectId: sessionStorage.getItem("projectId"),
      token: window.sessionStorage.getItem("token"),
      total: 0,
      pageSize: 10,
      currentPage: 1,
      operateForm: {
        code: "",
        typeId: "",
        quotaId: ""
      },
      // 监测类型
      typeOptions: [
        {
          id: 1,
          name: "自动化测点"
        },
        {
          id: 2,
          name: "人工测点"
        }
      ],
      // 监测指标
      targetOptions: [],
      selectTables: [],
      tableData: [],
      // 动态理论值 表格数据
      dynamicTableData: [],
      isShowTemplateUl: false,
      isShowImportUl: false,
      valueDialogVisible: false,
      // 理论值
      theoreticalValue: "",
      // 动态理论值 复选框
      dynamicValue: false,
      // 是否是批量处理
      isMultiple: false,
      // 说明
      description: "说明：动态理论值用于施工期，随施工期进程不断变换的理论值。",
      uploadDialogVisible: false,
      uploadForm: { file: [] },
      uploadRules: {
        file: [{ required: true, message: "请选择文件", trigger: "blur" }]
      },
      // 理论值详情 前端从表格行数据带过去
      details: {},
      // 上传的文件
      uploadFileList: [],
      operateType: "",
      tableHeight:0
    };
  },
  mounted() {
    this.getTableData();
    this.getSize();
    window.addEventListener("resize", () =>  { 
      this.getSize();
    }, false);
  },
  methods: {
    // 获取表格数据
    getTableData() {
      this.$axios.get(`${this.baseURL}theory/page?code=${this.operateForm.code}&pageNum=${this.currentPage}&pageSize=${this.pageSize}&projectId=${this.projectId}&quotaId=${this.operateForm.quotaId}&typeId=${this.operateForm.typeId}`).then((res) => {
        console.log("获取理论值表格数据---", res);
        if (res.data.status == "200") {
          let detail = res.data.data;
          this.tableData = detail.records;
          this.total = detail.total;
        }
      });
    },
    // 查询
    handleSearch() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 重置
    handleReset() {
      this.$set(this.operateForm, "type", "");
      this.$set(this.operateForm, "quotaId", "");
      this.$set(this.operateForm, "code", "");
      this.currentPage = 1;
      this.getTableData();
    },
    // 切换监测类型 获取相对应的监测指标
    handleTypeChange(val) {
      // 清空监测指标 联动获取
      this.$set(this.operateForm, "quotaId", "");
      this.$axios.get(`${this.baseURL}setttings/settingsThresholdQuota/quota/list?type=${val}&projectId=${this.projectId}`).then((res) => {
        console.log("获取监测指标---", res);
        this.targetOptions = res.data.data;
      });
    },
    // 获取详情
    getDetails(id, type) {
      this.$axios.get(`${this.baseURL}theory/info?id=${id}&type=${type}`).then((res) => {
        console.log("查询详情---", res);
        if (res.data.status == "200") {
          let detail = res.data.data;
          this.theoreticalValue = detail.value;
          detail.dynamicSelected && detail.dynamicSelected == 1 ? (this.dynamicValue = true) : (this.dynamicValue = false);
          this.dynamicTableData = detail.list;
          this.valueDialogVisible = true;
        }
      });
    },
    // 设置
    handleSettings(row) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.details = row;
      this.isMultiple = false;
      this.dynamicValue = false;
      this.getDetails(row.id, row.typeId);
      // this.valueDialogVisible = true;
    },
    // 批量处理
    handleMultiple() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.isMultiple = true;
      this.dynamicValue = false;
      this.valueDialogVisible = true;
    },
    // 复选框勾选
    handleSelectionChange(val) {
      // console.log(val)
      this.selectTables = val;
    },
    // 理论值设置 保存
    handleValueSave() {
      console.log("当前操作类型---", this.isMultiple);
      if (this.isMultiple) {
        // 批量处理
        if (!this.theoreticalValue) {
          this.$message.warning("请输入理论值");
          return;
        }
        let data = [];
        this.selectTables.forEach((item) => [
          data.push({
            id: item.id,
            type: item.typeId
          })
        ]);
        this.$axios.post(`${this.baseURL}theory/save/list?value=${this.theoreticalValue}`, data).then((res) => {
          if (res.data.status == "200") {
            this.valueDialogVisible = false;
            this.$message.success("设置成功");
          }
        });
      } else {
        let data = {
          id: this.details.id,
          type: this.details.typeId,
          value: this.theoreticalValue
        };
        if (this.dynamicValue) {
          // 动态理论值
          data.dynamicSelected = 1;
          data.list = this.dynamicTableData;
        } else {
          // 静态理论值
          data.dynamicSelected = 0;
        }
        this.$axios.post(`${this.baseURL}theory/save`, data).then((res) => {
          console.log("理论值设置---保存---", res);
          if (res.data.status == "200") {
            this.valueDialogVisible = false;
            this.$message.success("设置成功");
          }
        });
      }
    },
    // 动态理论值 切换选中状态
    handleDynamicValueChange(val) {
      console.log("切换动态理论值选中状态----", val);
    },
    // 条纹
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    handleMoveDownLoad(flag) {
      if (flag == "2") {
        if (!this.myDynamic) {
          this.$message.error("您没有权限！");
          return;
        }
      }

      this.$axios.get(`${this.baseURL}theory/download?projectId=${this.projectId}&template=${flag}&code=${this.operateForm.code}&quotaId=${this.operateForm.quotaId}&typeId=${this.operateForm.typeId}`, { responseType: "blob" }).then((res) => {
        console.log("理论值模板下载---保存---", res);
        if (res.status == "200") {
          // this.valueDialogVisible = false;
          // this.$message.success("设置成功");

          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel,charset=utf-8"
          });
          const filename = "模板下载";
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            const elink = document.createElement("a");
            elink.download = filename + ".xlsx";
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click(); //下载文件
            URL.revokeObjectURL(elink.href); // 释放URL 对象（释放内存）
            document.body.removeChild(elink);
          }
        }

        // let a = document.createElement("a");
        //   let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
        //   let objectUrl = URL.createObjectURL(blob);
        //   a.setAttribute("href", objectUrl);
        //   a.setAttribute("download", this.reportName + ".xlsx");
        //   a.click();
      });
    },
    // 移入
    handleTemplateEnter() {
      // if (!this.myUpload) {
      //   this.$message.error("您没有权限！");
      //   return;
      // }
      this.isShowTemplateUl = true;
    },
    handleTemplateLeave() {
      this.isShowTemplateUl = false;
    },
    handleImportEnter() {
      // if (!this.myUpload) {
      //   this.$message.error("您没有权限！");
      //   return;
      // }
      this.isShowImportUl = true;
    },
    handleImportLeave() {
      this.isShowImportUl = false;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    // 关闭理论值弹窗
    handleValueDialogClose() {
      this.valueDialogVisible = false;
      this.theoreticalValue = "";
      this.dynamicTableData = [];
    },
    // 上传change事件
    handleChange(file, fileList) {
      // 图片大小限制
      const isLt50M = file.size / 1024 / 1024 <= 50;
      if (!isLt50M) {
        this.$message.error("上传文件不得超过50M");
        fileList.splice(-1, 1);
      } else {
        this.uploadFileList = fileList;
        this.uploadForm.file = fileList;
      }
    },
    // 移除文件
    handleRemove() {
      this.handleChange(file, fileList);
    },
    // 静态导入
    handleQuietImport() {
      this.operateType = "quiet";
      this.uploadDialogVisible = true;
    },
    // 动态导入
    handleMoveImport() {
      if (!this.myDynamic) {
        this.$message.error("您没有权限！");
        return;
      }
      this.operateType = "move";
      this.uploadDialogVisible = true;
    },
    // 导入 保存
    handleUploadSave() {
      console.log("this.uploadFileList", this.uploadFileList);

      this.$refs.uploadForm.validate((valid) => {
        if (valid) {
          this.loadingPage = this.$loading({
            lock: true,
            text: "正在加载...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          let formData = new FormData();
          // formData.append("title", this.ruleForm.title);
          this.uploadFileList.map((item) => {
            formData.append("file", item.raw);
          });

          let flag = this.operateType == "quiet" ? "1" : "2";

          this.$axios
            .post(`${this.baseURL}theory/import?projectId=${this.projectId}&template=${flag}`, formData)
            .then((res) => {
              this.loadingPage.close();
              this.handleUploadDialogClose();
              console.log("文件上传", res);
              res = res.data;
              if (res.errCode == 200) {
                this.$message({
                  message: "上传成功",
                  type: "success"
                });
                this.currentPage = 1;
                this.getTableData();
              } else {
                this.$message({
                  message: res.errMsg,
                  type: "error"
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: err,
                type: "error"
              });
              this.loadingPage.close();
              this.handleUploadDialogClose();
            });
        }
      });
    },
    // 关闭导入弹窗
    handleUploadDialogClose() {
      this.uploadDialogVisible = false;
      this.uploadFileList = [];
      this.uploadForm.file = [];
    },
    getSize(){
        this.tableHeight = window.innerHeight - 330
    }
  },
  components: {
    CommonPagination
  }
};
</script>

<style lang="scss" scoped>
::v-deep .my-dialog div.el-dialog {
  width: 410px !important;
}
.search-wrapper {
  justify-content: space-between;
  line-height: 40px;
  margin-bottom: 25px;
  margin-top: 20px;
  .search {
    .el-form--inline .el-form-item {
      margin-bottom: 0;
    }
    span.line {
      margin-right: 10px;
    }
    ::v-deep .el-input .el-input__inner {
      width: 180px;
      height: 32px;
      line-height: 32px;
    }
    ::v-deep .my-type .el-input .el-input__inner {
      width: 200px;
    }
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 225px !important;
    }
    ::v-deep .el-date-editor input.el-input__inner {
      width: 100%;
      padding-right: 5px;
    }
    .reset-btn {
      background: none;
      border: 1px solid #2667db;
    }
  }
  .btn-right-wrapper {
    display: flex;
    align-items: center;
    .el-button {
      padding: 0 17px;
    }
    .template-wrapper,
    .import-wrapper {
      position: relative;
      margin-left: 20px;
      ul {
        position: absolute;
        top: 40px;
        right: 0;
        width: 120px;
        background: #233365;
        z-index: 1;
        font-size: 14px;
        text-align: center;
        border-radius: 4px;
        li:hover {
          cursor: pointer;
          background: #2667db;
        }
      }
    }
  }
  .table-wrapper {
    overflow: auto;
  }
}
::v-deep .hui1 {
  background: #091d46 !important;
}
::v-deep .lv1 {
  background: #0c265a !important;
}
::v-deep span.el-checkbox__input.is-disabled .el-checkbox__inner {
  background: #999 !important;
}
::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  font-size: 14px;
  text-align: center;
}
::v-deep .el-button.is-disabled {
  border: 1px solid #314c98;
  background: none;
  color: #314c98;
}
::v-deep span.el-checkbox__label {
  font-size: 12px !important;
}
::v-deep .little-dialog .el-table__header-wrapper {
  height: 32px;
  line-height: 32px;
}
::v-deep .little-dialog div.el-table {
  th,
  td {
    height: 32px;
    line-height: 32px;
    background-color: #194487 !important;
    padding: 0 !important;
    div.cell {
      font-size: 12px;
      .el-input {
        /* width: 84% !important; */
        .el-input__inner {
          height: 85%;
          border-radius: 0;
          border: 1px solid #4462a9;
        }
      }
    }
  }
  tr:nth-child(odd) td {
    background: #163c77 !important;
  }
  tr:nth-child(even) td {
    background: #123367 !important;
  }
}
.common-page.pageMain {
  margin-top: 15px;
}
.my-value-table {
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #4462a9;
    padding: 8px;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.7px;
  }
  th {
    width: 100px;
  }
}
.my-multiple-table {
  display: flex;
  flex-wrap: wrap;
  border-collapse: collapse;
  tr {
    width: 50%;
    display: flex;
    td {
      flex: 1;
      border: 1px solid #4462a9;
      padding: 8px;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.7px;
    }
  }
}
.value-input-wrapper {
  display: flex;
  align-items: center;
  margin-top: 15px;
  span.fs12 {
    width: 65px;
  }
  .el-input {
    flex: 1;
  }
  span.unit {
    margin-left: 15px;
  }
  ::-webkit-input-placeholder {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 12px;
    color: #6e88cb !important;
  }
}
.content {
  height: 370px;
  margin: 15px 0;
  ::v-deep .el-table__body-wrapper {
    height: 340px;
    overflow-y: auto;
  }
  ::-webkit-input-placeholder {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 12px;
    color: #597ac3 !important;
    letter-spacing: 0.7px;
  }
}
.upload-btn {
  width: 88px !important;
  border: 1px solid #28a0d8 !important;
  background: none !important;
  padding: 0;
  margin-right: 15px;
  color: #1dc7fc !important;
  /* &:hover {
            border: 1px solid #28a0d8;
            background: none;
            color: #1dc7fc;
        } */
}
.tips {
  font-weight: 400;
  font-size: 12px;
  color: #9c9c9c;
  letter-spacing: 0.7px;
}
/* ::v-deep div.common-dialog.little-dialog .el-dialog {
        width: 600px !important;
    } */
</style>
